.aboutMe {
	font-size: 1.5em;
	padding: 1em;
}

.profilePic {
	height: 13em;
	float: left;
	border-radius: 50%;
	border: 0.4em solid black;
	background-clip: content-box;
	margin: 0.5em;
	margin-right: 1em;
	shape-outside: inset(15em 15em 15em 15em 0px);
}

.profileText {
	text-align: justify;
}
