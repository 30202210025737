.wrapper {
	position: absolute;
	top: 50%;
	left: 50%;
	height: 80vh;
	width: 80vw;
	text-align: center;
	transform: translate(-50%, -50%);
	box-shadow: 1.5vmin 1.5vmin rgba(0,0,0,0.7);
}

.App-header {
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
